<template>
    <div class="">
        <div class="">
            <div class="">
                <h5 class="mb-1">{{item.title}}</h5> 
                <small class="text-muted mb-0"> ({{item.type.replace(/_/g, ' ')}})</small>
            </div>
            <div class="mb-1">{{item.description}}</div>
            <div class="px-2">
                <div class="row">
                    <div class="col-md-4 col-6 col-lg-2 custom-control custom-switch">
                        <input type="checkbox" :checked="item.is_active" class="custom-control-input">
                        <label class="custom-control-label" >Visibility</label>
                    </div>
                    <div class="col-md-4 col-6 col-lg-2 custom-control custom-switch">
                        <input type="checkbox" :checked="item.multiple" class="custom-control-input">
                        <label class="custom-control-label">Multiple</label>
                    </div>
                    <div class="col-md-4 col-6 col-lg-2 custom-control custom-switch">
                        <input type="checkbox" :checked="item.required" class="custom-control-input">
                        <label class="custom-control-label">Required</label>
                    </div>
                </div> 
            </div> 
            <div class="mt-3">
                <slot name="actions"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        item:{
            type: Object,
            required: true
        }
    }
}
</script>