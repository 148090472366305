
<template>
  <div class="product-addons">
    <div class="row">
      <div class="col-12">
        <div class="mb-3 bg-light d-flex align-items-center justify-content-between p-2">
          <h5 class="my-0">
          <i class="mdi mdi-playlist-edit mr-1"></i> Product Addons
          </h5>
        </div>
        <div role="tablist" class="">
          <b-card no-body class="mb-1 shadow-none" v-for="(addon, index) in addons" :key="index">
            <b-card-header header-tag="header" class="bg-transparent" role="tab">
              <div class="d-sm-flex">
                <h5 class="m-0 flex-grow-1 d-flex align-items-center">
                  <a v-b-toggle="`addonAccordion-${index}`" class="text-dark" href="javascript: void(0);">
                    <i class="mdi mdi-check-circle mr-1 text-primary"></i>{{addon.name}} - ${{addon.charge}}
                  </a>
                </h5>
                <span class="float-right">
                  <b-dropdown variant="light" size="sm" right>
                    <template slot="button-content">
                        Action
                    </template>
                    <b-dropdown-item-button @click="initEdit(addon)"><i class="mdi mdi-square-edit-outline"></i> Edit</b-dropdown-item-button>
                    <b-dropdown-item-button @click="deleteItem(addon.id)"><i class="mdi mdi-trash-can-outline"></i> Delete</b-dropdown-item-button>
                  </b-dropdown>
                </span>
              </div>
            </b-card-header>
            <b-collapse :id="`addonAccordion-${index}`" accordion="accordion" role="tabpanel">
              <b-card-body>
                <h6>Charge: ${{addon.charge}}</h6>
                <b-card-text>Duration: {{calculateDeliveryTime(addon.duration)}}</b-card-text>
                <b-card-text>{{addon.description}}</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="d-flex justify-content-end">
          <b-button variant="warning" class="mr-2" @click.prevent="popupClone = true" type="button">Copy from</b-button>
          <b-button variant="primary" @click.prevent="popupModalShow = true" type="button">Add Addon</b-button>
        </div>
      </div>
    </div>
    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode? 'Update addon':'Add addon'"
      title-class="font-18" @hide="initializeAll()">
      <product-addon-form v-if="popupModalShow" @closeMe="initializeAll()"
      :editMode="editMode" :productId="product.id" :editItem="editItem" />
    </b-modal>

    <b-modal v-model="popupClone" hide-footer centered
      title="Select product to copy from"
      title-class="font-18" @hide="initializeAll()">
      <div>
        <select v-model="cloneFrom"
          class="form-control mb-3" placeholder="--select product-">
          <option selected :value="null" >--select product--</option>
          <option v-for="(dPro, iIndex) in products" :key="iIndex"
          :value="dPro.id" >{{dPro.name}}</option>
        </select>
        <b-button variant="primary" @click.prevent="cloneNow()" type="button">Clone</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ProductAddonForm from '@/components/forms/ProductAddonForm.vue'

export default {
  components:{ProductAddonForm},
  data() {
    return {
      popupModalShow: false,
      editMode: false,
      editItem: null,
      popupClone: false,
      cloneFrom: null
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product
    },
    addons(){
      return this.product.addons
    },
    products(){
      return this.$store.state.productList.products.map((p) => {return {id: p.id, name:p.name}})
    },
  },
  methods: {
    cloneNow(){
      if(!this.cloneFrom){this.alertError("Select product"); return;}
      let data = {from: this.cloneFrom, to: this.product.id}
      this.$store.dispatch("changeLoaderValue",true)
      this.$http.post('/addons/clone', data)
      .then((response) => {
        this.$store.dispatch("changeLoaderValue")
        if(response.data.success){
          this.$store.dispatch("productList/fetchProduct", this.product.id)
          this.initializeAll()
        }
      })
    },
    deleteItem(itemId){
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete a addon!",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#e11",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/addons/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$store.commit("productList/DELETE_ADDON", itemId)
            }
          })
        }
      });
    },
    initEdit(items){
      this.editMode = true;
      this.editItem = {...items}
      this.popupModalShow = true  
    },
    initializeAll(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
      this.popupClone = false;
      this.cloneFrom = null
    },
  },
}
</script>
