<template>
  <div id="product-show-page">
    <div v-if="!isLoading">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-2"> {{product.name}}</h4>
              <b-tabs nav-class="nav-tabs nav-bordered">
                <b-tab title="Overview">
                  <product-overview active/>
                </b-tab>
                <b-tab title="Description">
                  <product-description />
                </b-tab>
                <b-tab title="Attributes">
                  <product-attributes />
                </b-tab>
                <b-tab title="Packages">
                    <product-packages />
                </b-tab>
                <b-tab title="Files">
                  <product-files />
                </b-tab>
                <b-tab title="Settings">
                  <product-settings />
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <is-loading v-else />
  </div>
</template>

<script>
import ProductOverview from "@/components/product/ProductOverview.vue"
import ProductDescription from "@/components/product/ProductDescription.vue"
import ProductPackages from "@/components/product/ProductPackages.vue"
import ProductFiles from "@/components/product/ProductFiles.vue"
import ProductSettings from "@/components/product/ProductSettings.vue"
import ProductAttributes from "@/components/product/ProductAttributes.vue"
import IsLoading from "@/components/IsLoading.vue"

export default {
  components: {
    ProductOverview,
    ProductDescription,
    ProductPackages,
    ProductSettings,
    ProductFiles,
    ProductAttributes,
    IsLoading
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product
    }
  },
  created(){
    this.$store.dispatch("productList/fetchProducts")
    this.$store.dispatch("categoryList/fetchCategories")
    this.$store.dispatch("productList/fetchProduct", this.$route.params.productId)
    .then((response) => {
      if(response.data.success){
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: '/error-404'})
        }
      }
    })
  },
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
