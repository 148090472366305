<template>
<div>
    <b-img thumbnail fluid :src="absoluteUrl(product.image)" alt="product-img"></b-img>
    <b-button variant="light" class="mt-2" size="sm" @click.prevent="popupUpdateImage = true">Upate Product Image</b-button>
    <b-modal v-model="popupUpdateImage" hide-footer centered
      title="Update Product Image" title-class="font-18">
      <vue-dropzone id="productImageUpdateDrop" ref="productImageUpdateDrop"
      :use-custom-slot="true" :maxFiles="1">
        <div class="needsclick m-0">
          <i class="h1 text-muted ri-upload-cloud-2-line"></i>
          <h4>Drop files here or click to upload.</h4>
          <span class="text-muted font-13">
           (Each File should not be more than 2MB)
          </span>
        </div>
      </vue-dropzone>
      <div class="d-flex mt-3 justify-content-end">
        <b-button class="mr-1" @click.prevent="popupUpdateImage = false">Close</b-button>
        <b-button variant="primary" @click.prevent="updateProduct()" type="button">Upload</b-button>
      </div>
    </b-modal>
</div>
</template>

<script>
import VueDropzone from '@/components/VueDropzone.vue';

export default {
  components: { VueDropzone },
  name: "product-image",
  data() {
    return {
      popupUpdateImage:false,
    }
  },
  computed:{
    product(){
      return this.$store.state.productList.product;
    }
  },
  methods: {
    updateProduct() {
      if(!this.collectFiles().length){ this.alertError("Image file is required"); return;}
      const formData = new FormData()
      formData.append('image', this.collectFiles()[0])
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/products/${this.product.id}/update-image`, formData)
        .then((response) => {
        this.$store.dispatch("changeLoaderValue")
        if(response.data.success){
          this.removeFiles()
          this.$store.commit("productList/UPDATE_PRODUCT", response.data.data.product)
        }
      })
    },
    collectFiles(){
      var arrafile = [];
      if(this.$refs.productImageUpdateDrop){
        arrafile = [...this.$refs.productImageUpdateDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    removeFiles() {
      this.$refs.productImageUpdateDrop.removeAllFiles();
      this.popupUpdateImage = false
    }
  }
}

</script>

