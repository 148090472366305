
<template>
  <div class="product-requirements">
    <div class="row">
      <div class="col-12">
        <div class="mb-3 bg-light d-flex align-items-center justify-content-between p-2">
          <h5 class="my-0">
            <i class="mdi mdi-playlist-edit mr-1"></i> Product Requirements
          </h5>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div role="tablist" class="">
          <b-card no-body class="mb-1 shadow-none" 
            v-for="(requirement, index) in requirements" :key="index">
            <b-card-header header-tag="header" class="bg-transparent" role="tab">
              <div class="d-sm-flex">
                <h5 class="m-0 flex-grow-1 d-flex align-items-center">
                  <a v-b-toggle="`addonAccordion-${index}`" class="text-dark" href="javascript: void(0);">
                    <i class="mdi mdi-check-circle mr-1 text-primary"></i>{{requirement.name}}
                  </a>
                </h5>
              </div>
            </b-card-header>
            <b-collapse :id="`addonAccordion-${index}`" accordion="accordion" role="tabpanel">
              <b-card-body>
                <requirement-card :item="requirement">
                  <template v-slot:actions>
                    <div>
                      <b-button variant="success" size="sm" class="mr-2" @click.prevent="initEdit(requirement)" type="button">
                        Edit </b-button>
                      <b-button variant="primary" size="sm" class="mr-2" @click.prevent="showValues(requirement)" type="button">
                         View Values </b-button>
                      <b-button variant="danger" size="sm" @click.prevent="deleteItem(requirement.id)" type="button">
                        Delete</b-button>
                    </div>
                  </template>
                </requirement-card>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>

      <div class="col-12 mt-3 text-right">
        <b-button variant="warning" size="sm" class="mr-2" @click.prevent="popupClone = true" type="button">
          Copy from </b-button>
        <b-button variant="primary" size="sm" @click.prevent="popupModalShow = true" type="button">
          Add Requirement</b-button>
      </div>
    </div>

    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode ? 'Update requirement':'Add requirement'"
      title-class="" @hide="initializeAll()">
      <requirement-form v-if="popupModalShow" @closeMe="initializeAll()"
       :editMode="editMode" :productId="product.id" :editItem="showItem" />
    </b-modal>

    <b-modal v-model="popupModalValues" size="lg" hide-footer centered
      title="Requirement Values"
      title-class="" @hide="initializeAll()">
      <product-requirement-values v-if="popupModalValues"
       :showItem="showItem" />
    </b-modal>

    <b-modal v-model="popupClone" hide-footer centered
      title="Select product to copy from"
      title-class="font-18" @hide="initializeAll()">
      <div>
        <select v-model="cloneFrom"
          class="form-control mb-3" placeholder="--select product-">
          <option selected :value="null" >--select product--</option>
          <option v-for="(dPro, iIndex) in products" :key="iIndex"
          :value="dPro.id" >{{dPro.name}}</option>
        </select>
        <b-button variant="primary" @click.prevent="cloneNow()" type="button">Clone</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RequirementForm from '@/components/forms/RequirementForm.vue';
import RequirementCard from '@/components/cards/RequirementCard.vue';
import ProductRequirementValues from './ProductRequirementValues.vue';

export default {
  components: {
    RequirementForm, RequirementCard, 
    ProductRequirementValues
  },
  data() {
    return {
      popupModalShow: false,
      popupModalValues: false,
      editMode: false,
      showItem: null,
      popupClone: false,
      cloneFrom: null
    }
  },
  computed:{
    product(){
      return this.$store.state.productList.product
    },
    requirements(){
      return this.product.requirements
    },
    products(){
      return this.$store.state.productList.products.map((p) => {return {id: p.id, name:p.name}})
    },
  },
  methods: {
    cloneNow(){
      if(!this.cloneFrom){ this.alertError("Select product"); return;}
      let data = {from: this.cloneFrom, to: this.product.id}
      this.$store.dispatch("changeLoaderValue",true)
      this.$http.post('/product-requirements/clone', data)
      .then((response) => {
        this.$store.dispatch("changeLoaderValue")
        if(response.data.success){
          this.$store.dispatch("productList/fetchProduct", this.product.id)
          this.initializeAll()
        }
      })
    },
    deleteItem(itemId){
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this requirement!",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#e11",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/product-requirements/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
               this.$store.commit("productList/DELETE_REQUIREMENT", itemId)
            }
          })
        }
      });
    },
    initEdit(item){
      this.editMode = true;
      this.showItem = {...item}
      this.popupModalShow = true  
    },
    showValues(item){
      this.showItem = {...item}
      this.popupModalValues = true  
    },
    initializeAll(){
      this.editMode = false;
      this.showItem = null;
      this.popupModalShow = false;
      this.popupModalValues = false;
      this.popupClone = false;
      this.cloneFrom = null
    },
  },
}
</script>
