<template>
    <form class="">
        <div class="form-group mb-3">
          <label for="question">
              Question
            <span class="text-danger">*</span>
          </label>
          <input type="text" v-validate="{required: true}" v-model="data.question" id="question" name="question" class="form-control" :class="{ 'is-invalid': errors.has('question') }" placeholder="Enter question" />
          <span class="text-danger invalid-feedback"  v-show="errors.has('question')">{{ errors.first('question') }}</span>
        </div>
        <div class="form-group mb-3">
           <label class="">Answer</label>
            <textarea v-validate="'max:1000'" v-model="data.answer" class="form-control" rows="3" placeholder="Write Something" name="answer"></textarea>
            <span class="text-danger invalid-feedback"  v-show="errors.has('answer')">{{ errors.first('answer') }}</span>
        </div>
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="primary" @click.prevent="editMode? update() : create()" type="button">{{editMode? 'Update':'Submit'}}</b-button>
        </div>
    </form>
</template>

<script>
export default {
    props:{
        productId:{
            type: Number,
            default: null
        },
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                question: "",
                answer: "",
                product_id: this.productId
            },
        }
    },
    methods:{
        create() {
            this.$validator.validateAll().then(result => {
                if (!result) {this.alertError("Form not valid"); return }
                const formData = new FormData()
                this.$store.dispatch("changeLoaderValue", true)
                formData.append('data', JSON.stringify(this.data))
                this.$http.post("/product-questions/create", formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("productList/ADD_QUESTION", response.data.data.question)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
           this.$validator.validateAll().then(result => {
                if (!result) {this.alertError("Form not valid"); return }
                const formData = new FormData()
                this.$store.dispatch("changeLoaderValue", true)
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/product-questions/${this.editItem.id}/update`,formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("productList/UPDATE_QUESTION", response.data.data.question)
                        this.resetForm()
                    }
                })
            
            })
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = JSON.parse(JSON.stringify(this.editItem))
        }
    }
}
</script>

<style>

</style>